/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <div
            style={{
              display: `flex`,
            }}
          >
            <Image
              fixed={data.avatar.childImageSharp.fixed}
              alt={author}
              style={{
                marginRight: rhythm(1 / 2),
                marginBottom: 0,
                minWidth: 50,
                borderRadius: `100%`,
              }}
              imgStyle={{
                borderRadius: `50%`,
              }}
            />
            <p>
				Bonjour! Je suis <strong>Brice Coquereau</strong>, parisien,
				développeur, flexitarien, et amateur de chats. Je vais à des{' '}
				<Link to={'/meetups'}>meetups</Link> assez souvent. Venez me
				parler sur{' '}
				<a
					href={`https://${social.mastodonInstance}/@${social.mastodonUser}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					Mastodon
				</a>{' '}
				ou{' '}
				<a
					href={`https://twitter.com/${social.twitter}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					Twitter
				</a>{' '}
				(mais Mastodon c'est mieux)
            </p>
          </div>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/highres_374241362-100.jpeg/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
          mastodonInstance
          mastodonUser
        }
      }
    }
  }
`

export default Bio
